import { graphql, useStaticQuery } from 'gatsby';
import * as transformers from '@content/transformers/HCPPage';

export const hcpPageData = () => {
  const data = useStaticQuery(graphql`
    query hcpPageQuery {
      dGraphQl {
        nodeQuery(filter: { conditions: [{ field: "type", value: "hcp_page" }] }) {
          entities {
            ... on DrupalGraphQl_NodeHcpPage {
              entityLabel
              uuid
              path {
                alias
              }
              fieldBannerTop {
                entity {
                  ...DrupalGraphQlParagraphBannerPage
                }
              }
              fieldContent {
                entity {
                  ...DrupalGraphQlParagraphContent
                }
              }
              fieldSection {
                entity {
                  ... on DrupalGraphQl_ParagraphSection {
                    fieldSubmenuName
                    fieldAnchorId
                    fieldLayout {
                      entity {
                        ...DrupalGraphQlParagraphContent
                        ...DrupalGraphQlParagraphTitle
                        ...DrupalGraphQlParagraphBackgroundSection
                        ...DrupalGraphQlParagraphButtonLink
                        ...DrupalGraphQlParagraphDownload
                        ...DrupalGraphQlParagraphImage
                        ...DrupalGraphQlParagraphSymptomsPatients
                        ...DrupalGraphQlParagraphTextLeftImageRight
                        ...DrupalGraphQlParagraphWarningText
                        ...DrupalGraphQlParagraphTable
                        ...DrupalGraphQlParagraphPublication
                        ...DrupalGraphQlParagraphMultiLines
                        ...DrupalGraphQlParagraphEvent
                        ...DrupalGraphQlParagraphSocialMedia
                        ...DrupalGraphQlParagraphImageLeftTextRight
                        ...DrupalGraphQlParagraphImageAnd4Descriptions
                      }
                    }
                    uuid
                  }
                }
              }
              fieldMetatags {
                entity {
                  ... on DrupalGraphQl_ParagraphMetatags {
                    fieldDescription
                    fieldKeywords
                  }
                }
              }
              fieldMetaTitle
            }
          }
        }
      }
    }
  `);

  return transformers.hcpPageTransform(data.dGraphQl);
};
