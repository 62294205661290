import get from 'lodash.get';
import calendarIcon from '@assets/svg/calendar.svg';
import disabledCalendarIcon from '@assets/svg/disabled_calendar.svg';
import pushpinIcon from '@assets/svg/pushpin.svg';
import disabledPushpinIcon from '@assets/svg/disabled_pushpin.svg';

export const hcpEventsTransform = (query) => {
  const queryData = query.nodeQuery;
  const data = get(queryData, 'entities', []).map((event) => ({
    city: get(event, 'fieldEventPlace', ''),
    event_name: get(event, 'title', ''),
    image1: calendarIcon,
    image2: pushpinIcon,
    image3: disabledCalendarIcon,
    image4: disabledPushpinIcon,
    date: {
      start: get(event, 'fieldEventDate.entity.fieldDateStart.value', null),
      end: get(event, 'fieldEventDate.entity.fieldDateStop.value', null),
    },
    url: get(event, 'fieldEventLink.url.path', ''),
    category: get(event, 'fieldEventCategory.entity.name', ''),
    uuid: get(event, 'uuid', ''),
  }));

  return data;
};
