import React, { useEffect } from 'react';

// === Components === //
import { Redirect } from '@reach/router';
import Page from '@components/layout/Page';
import HeroBanner from '@components/sections/HeroBanner';
import Paragraph from '@components/common/Paragraph';
import Container from '@components/common/Container';
import PageSections from '@components/sections/PageSections';

// === Content === //
import { hcpPageData } from '@graphql/HcpPage';
import { hcpEventsData } from '@graphql/HcpEvents';

const HcpPage = (props) => {
  const variant = 'red';
  const pageType = 'hcp_page';
  const pages = hcpPageData();
  const events = hcpEventsData();
  const page = pages.find((page) => page.uuid === props.pageContext.uuid);

  useEffect(() => {
    setTimeout(() => {
      if (typeof window !== 'undefined') {
        if (window?.location?.hash && window?.location?.hash !== '') {
          const elementId = window?.location?.hash.split('#')[1];
          const element = document.getElementById(elementId);

          element?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }
    }, 300);
  }, []);

  return page ? (
    <Page
      variant={variant}
      pageType={pageType}
      navUuid={page.uuid}
      bottomSpace={true}
      metaData={page.metaData}
      pageUrl={`/${props.pageContext.slug}`}
    >
      <>
        <HeroBanner
          image={page.heroBanner.image.src}
          image_mobile={page.heroBanner.imageMobile.src}
          text={page.heroBanner.text}
        />
        {page.startText.map((text, index) => (
          <Container key={index}>
            <Paragraph text={text} variant={variant} key={index} />
          </Container>
        ))}
        <PageSections sections={page.sections} variant={variant} events={events} />
      </>
    </Page>
  ) : (
    <Redirect to="/404" />
  );
};

export default HcpPage;
