import { graphql, useStaticQuery } from 'gatsby';
import * as transformers from '@content/transformers/HCPEvents';

export const hcpEventsData = () => {
  const data = useStaticQuery(graphql`
    query hcpEventsQuery {
      dGraphQl {
        nodeQuery(filter: { conditions: { field: "type", value: "hcp_event" } }, limit: 1000) {
          entities {
            ... on DrupalGraphQl_NodeHcpEvent {
              uuid
              fieldEventCategory {
                entity {
                  ... on DrupalGraphQl_TaxonomyTermEventCategory {
                    name
                  }
                }
              }
              fieldEventDate {
                entity {
                  ... on DrupalGraphQl_ParagraphEventDate {
                    fieldDateStart {
                      date
                      value
                    }
                    fieldDateStop {
                      date
                      value
                    }
                  }
                }
              }
              fieldEventLink {
                title
                url {
                  path
                }
              }
              fieldEventPlace
              title
            }
          }
        }
      }
    }
  `);

  return transformers.hcpEventsTransform(data.dGraphQl);
};
